<script setup>
import { baselayers, mapStyles } from '@/config/map'
import { useMapStore } from '@/store/map'
import { ref, computed } from 'vue'
import { SPopTip, SText, SIcon } from '@n966/soilbox_ui'

const props = defineProps({
  mapgl: {
    type: Object,
    required: true
  }
})

const store = useMapStore()

let showList = ref(false)

let baselayer = computed({
  get() {
    return store.activeBaselayer
  },
  set(value) {
    store.SET_BASELAYER(value)
    const style = store.activeBaselayer
    props.mapgl.setStyle(mapStyles[style])
  }
})

function clickHandler(id) {
  if (baselayer.value === id) return

  baselayer.value = id
  showList.value = false
}

function getClassName(id) {
  if (id === baselayer.value) {
    return 'map-baselayers__item map-baselayers__item--active'
  }

  return 'map-baselayers__item'
}
</script>

<template>
  <div class="map-baselayers">
    <s-pop-tip
      v-model="showList"
      width="160"
      trigger="click"
      placement="bottom-end"
    >
      <button
        v-tooltip="'Слои'"
        :class="`map-baselayers__button ${
          showList ? 'map-baselayers__button--show' : ''
        }`"
      >
        <s-icon
          name="layer-group"
          size="lg"
        />
      </button>
      <template v-slot:content>
        <ul class="map-baselayers__list">
          <li
            v-for="layer in baselayers"
            :key="layer.id"
            :class="getClassName(layer.id)"
            @click="clickHandler(layer.id)"
          >
            <s-text>{{ layer.name }}</s-text>
          </li>
        </ul>
      </template>
    </s-pop-tip>
  </div>
</template>

<style lang="scss">
.map-baselayers {
  background-color: var(--bg);
  border: 1px solid var(--main-bg);
  border-radius: 8px;
  z-index: 10;

  &__button {
    width: 40px;
    height: 40px;
    background-color: var(--bg);
    border-radius: 8px;
    outline: none;
    border: none;
    color: var(--text);
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    & svg {
      width: 22px;
      height: 100%;
    }

    &--show {
      background-color: var(--primary--active);
    }
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  &__item {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 4px;
    }

    &:hover {
      background-color: var(--primary--select);
    }

    &--active {
      background-color: var(--primary--active);
    }
  }
}
</style>
