<script setup>
import { ref, computed, reactive, watch } from 'vue'
import { useMapStore } from '@/store/map'
import { SPopTip, SButton, SSearchBlockFilters } from '@n966/soilbox_ui'
import { filtersConfig } from './config'
import { filterData } from '@/utils'

const props = defineProps({
  updatePolygons: {
    type: Function,
    required: true
  }
})

const mapStore = useMapStore()

const filtersVisible = ref(false)
const filtersLocal = reactive(filtersConfig)

const active = computed(() => {
  return filtersLocal.filters.some((item) => {
    return item.filterValues?.some((e) => !e.value)
  })
})

watch(
  () => filtersLocal,
  (newValue) => {
    const { filters } = newValue
    const filteredList = filterData(mapStore.mapZones, filters)
    props.updatePolygons(filteredList)
  },
  { deep: true }
)
</script>
<template>
  <div class="map-polygons-filter">
    <s-pop-tip
      v-model="filtersVisible"
      placement="bottom-end"
      width="262"
      trigger="click"
    >
      <div v-tooltip.left="'Фильтрация зон'">
        <s-button
          :class="[
            {
              'map-polygons-filter__button--active': active
            }
          ]"
          class="map-polygons-filter__button"
          icon="filter"
        />
      </div>
      <template #content>
        <s-search-block-filters :filters="filtersConfig" />
      </template>
    </s-pop-tip>
  </div>
</template>

<style lang="scss">
.map-polygons-filter {
  &__button {
    position: relative;
    width: 40px;
    height: 40px;
    background-color: var(--bg);
    border-radius: 8px;
    outline: none;
    border: none;
    color: var(--text);
    padding: 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    &--active::before {
      z-index: 1;
      top: 12%;
      right: 12%;
      border-radius: 50%;
      content: '';
      width: 10px;
      height: 10px;
      background: var(--primary);
      border: 1px solid var(--card-bg);
      position: absolute;
    }

    & svg {
      width: 17.5px;
      height: 100%;
    }
  }

  &__list {
    margin: 0;
    padding: 0;
  }

  &__item {
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;

    &:not(:first-child) {
      margin-top: 4px;
    }

    &:hover {
      background-color: var(--primary--select);
    }

    &--active {
      background-color: var(--primary--active);
    }
  }
}
</style>
