<script setup>
import { useTableStore } from '@/store/table'
import { useMainStore } from '@/store'
import { useRouter } from 'vue-router'
import { STitle, SIcon, SText, SButton } from '@n966/soilbox_ui'
import updatesModal from './updates-modal.vue'

const tableStore = useTableStore()
const mainStore = useMainStore()
const router = useRouter()

const closeModal = () => {
  mainStore.isShowLastUpdatesModal = false
}

const openUpdatesModal = () => {
  mainStore.isShowLastUpdatesModal = true
}

const clickHandle = () => {
  router.push('/info')
}

const openArticle = () => {
  window.open(
    'https://geoinfo.ru/product/gladyshev-aleksandr-konstantinovich/magiya-transformacii-koordinat-ot-mestnogo-k-globalnomu-51340.shtml',
    '_blank'
  )
}
</script>

<template>
  <div class="header">
    <div class="header__left-part">
      <a
        target="_blank"
        class="header__link"
        href="https://soilbox.app/"
      >
        <img
          src="@/assets/images/soilbox_logotype.png"
          alt=""
        />
      </a>
      <s-title type="title">Пересчет координат</s-title>
    </div>
    <div
      class="header__loading"
      v-if="tableStore.isLoadingDataSheets"
    >
      <s-icon
        size="sm"
        name="spinner"
        spin
      />
      <s-text>Выполняется пересчет...</s-text>
    </div>
    <div class="header__right-part">
      <a
        target="_blank"
        href="https://t.me/soilbox"
        class="header__link-tg"
      >
        <img
          src="@/assets/images/telegram.svg"
          alt=""
        />
        <s-text type="primary">Новости Soilbox</s-text>
      </a>
      <a
        target="_blank"
        class="header__last-updates"
        @click="openUpdatesModal"
      >
        <s-text type="secondary">Обновления</s-text>
      </a>
      <s-button
        @click="openArticle"
        icon="up-right-from-square"
      >
        Статья
      </s-button>
      <div
        class="header__tooltip header__project-info"
        v-tooltip.left="'Информация о проекте'"
      >
        <s-button
          @click="clickHandle"
          icon="circle-question"
        >
          Справка
        </s-button>
      </div>
    </div>
  </div>

  <updates-modal
    :show-modal="mainStore.isShowLastUpdatesModal"
    @close-modal="closeModal"
  />
</template>

<style lang="scss">
.header {
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--bg);

  &__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    border: 1px solid var(--bg);
    background: var(--card-bg);
    border-radius: 8px;
    padding: 7px 16px;
  }

  &__last-updates {
    display: flex;
    text-decoration: none;
    color: var(--text);
    background-color: var(--bg);
    border-radius: 8px;
    padding: 0 1rem;
    height: 36px;
    align-items: center;
    transition: all 0.2s ease;
    gap: 8px;
    cursor: pointer;
    position: relative;

    &::before {
      font-size: 11px;
      position: absolute;
      top: -4px;
      right: -6px;
      content: attr(data-badge);
      width: 12px;
      height: 12px;
      color: var(--white-text);
      border: 1px solid var(--main-bg);
      background: var(--error);
      border-radius: 50%;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    & svg {
      height: 16px;
    }

    &:hover:not(.disabled) {
      transition: all 0.2s ease;
      background-color: var(--bg-lite);
    }

    &:active:not(.disabled) {
      transition: all 0.2s ease;
      background-color: transparent;
    }
  }

  &__link-article {
    display: flex;
    text-decoration: none;
    color: var(--text);
    background-color: var(--bg);
    border-radius: 8px;
    padding: 0 1rem;
    height: 36px;
    align-items: center;
    transition: all 0.2s ease;
    gap: 8px;
    cursor: pointer;

    & svg {
      height: 16px;
    }

    &:hover:not(.disabled) {
      transition: all 0.2s ease;
      background-color: var(--bg-lite);
    }

    &:active:not(.disabled) {
      transition: all 0.2s ease;
      background-color: transparent;
    }
  }

  &__link-tg {
    flex-shrink: 0;
    cursor: pointer;
    border: 1px solid #2792ed;
    border-radius: 8px;
    outline: 0;
    font-size: 16px;
    text-decoration: none;
    background-color: transparent;
    display: flex;
    height: 36px;
    gap: 8px;
    padding: 0 12px;

    &:hover {
      opacity: 0.9;
    }

    & .s-text {
      align-self: center;
    }

    & img {
      width: 20px;
    }
  }

  &__tooltip {
    display: flex;
    align-self: center;
  }

  &__link {
    font-size: 0;

    img {
      height: 32px;
    }
  }

  & .header__tooltip {
    & svg {
      cursor: pointer;
      height: 16px;
    }

    & a {
      height: 100%;
      display: flex;
    }
  }

  &__right-part {
    display: flex;
    gap: 16px;
    flex-shrink: 0;
    align-items: center;
  }

  &__left-part {
    display: flex;
    align-items: center;
    gap: 24px;

    & h1 {
      color: var(--text);
      font-weight: 600;
      font-size: 18px;
    }
  }
}
</style>
