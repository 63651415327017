export const filtersConfig = {
  title: 'Фильтры',
  filters: [
    {
      id: 'zone_name_width',
      filterTitle: 'Ширина зон',
      filterValues: [
        {
          id: '1.5 градусная',
          title: '1.5 градусная',
          value: true
        },
        {
          id: null,
          title: '3 градусная',
          value: true
        },
        {
          id: '6 градусная',
          title: '6 градусная',
          value: true
        }
      ]
    }
  ]
}
